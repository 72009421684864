import React from "react"

const Badge = () => {
  return (
    <a
      href="https://www.producthunt.com/posts/wappraiser?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-wappraiser"
      target="_blank"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=288584&theme=dark"
        alt="WAPPraiser - All web metrics in a simple report | Product Hunt"
        style={{ width: "250px", height: "54px" }}
        width="250"
        height="54"
      />
    </a>
  )
}

export default Badge
