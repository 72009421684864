import React from "react"

import styled from "styled-components"

import { Link } from "gatsby"

const Footer = () => (
  <FooterContainer>
    <ul>
      {/* <li>
        <Link to="/privacy">Privacy Policy</Link>
      </li> */}
      <li>
        <a href="mailto:contact@wappraiser.com">Contact us</a>
      </li>
    </ul>
  </FooterContainer>
)

const FooterContainer = styled.div`
  padding: 1em;
  background-color: ${({ theme }) => theme.color.accent};

  ul {
    list-style-type: none;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: white;
    text-decoration: none;
  }
`

export default Footer
